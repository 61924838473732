import React from "react";
import styles from "./Reload.module.css";
import { useRouter } from "next/router";

export default function Reload() {
    const router=useRouter()
    return (
        <div className={styles.successWrapper}>
            <div className={styles.boundary}>
                <div className={styles.upperBox}>
                    <figure>
                        <img
                            className="img-responsive"
                            src={`assets/images/requestRaiseError.svg`}
                            alt=""
                        />
                    </figure>
                    <div className={styles.message}>Local data has been cleared. Please reload the page to continue.</div>
                    
                </div>
                <div className={styles.lowerBox}>
                    <button className={styles.accept} onClick={() => router.reload()}>Reload</button>
                </div>
            </div>
        </div>
    );
}
