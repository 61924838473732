import React, { useState } from "react";
import styles from "./CommentBox.module.css";
import { useCanvasContext } from "@/libs/context/CanvasContext";
import { useGlobalContext } from "@/libs/context/GlobalContext";
import { StatusTab } from "../statusTab";
import Image from "next/image";
import Miscellaneous from "../miscellaneous/Miscellaneous";

type Comment = {
  x: number;
  y: number;
  id: number;
  parent:string;
  status: string;
  slideId: string;
  messages: {
    id: number;
    text: string;
    user: string;
    userId: number;
  }[];
};
type CommentGroup = {
  data: Comment[];
  slideId: string;
  parent:string;
  version:number
};

type props = {
  slideId: string;
  activeLowerBox: number;
  setActiveLowerBox: Function;
  allowRequestDqc: boolean;
  parent: string;
  slideDataApi: any;
};
export function CommentBox({
  slideId,
  activeLowerBox,
  setActiveLowerBox,
  allowRequestDqc,
  parent,
  slideDataApi
}: props) {
  const { comments, setComments } = useCanvasContext();
  const {
    props: { role, module },
  } = useGlobalContext();
  const [selectedTab, setSelectedTab] = useState("Open"); // Initial tab
  let filteredComments: CommentGroup[] = [];
  let miscellaneousComments: CommentGroup[] = [];

  const getUniqueElements = (array: any) => {
    return Array.from(new Set(array));
  };
  const getAllSlidesTypes = () => {
    const slideId = slideDataApi?.map((slide: any) =>
      slide.slideId
    );
    return getUniqueElements(slideId);
  };

  const getAllCommentsParents:any = () => {
    const ids = comments?.map((comment: any) => comment.slideId);
    return getUniqueElements(ids);
  };

  function getMiscellaneousParents(array1: any, array2: any) {
    return array2.filter((element: any) => !array1.includes(element));
  }

  if (comments && comments.length) {
    const miscellaneousParents = getMiscellaneousParents(
      getAllSlidesTypes(),
      getAllCommentsParents()
    );

    filteredComments = comments.map((commentGroup: any) => (
      {
      ...commentGroup,
      data: commentGroup.data.filter((comment: any) => {
        if (module === "Modular") {
          return commentGroup.slideId == slideId && comment.status === selectedTab;
        } else {
          return comment.status === selectedTab;
        }
      }),
    }));

    // Filter out objects where data array is empty
    filteredComments = filteredComments.filter((commentGroup: any) => commentGroup.data.length > 0);

    miscellaneousComments = comments.map((commentGroup: any) => ({
      ...commentGroup,
      data: commentGroup.data.filter((comment: any) => {
        if (module === "Modular") {
          return miscellaneousParents.includes(commentGroup.slideId) && comment.status === selectedTab;
        } else {
          return comment.status === selectedTab;
        }
      }),
    }));
    // Filter out objects where data array is empty
    miscellaneousComments = miscellaneousComments.filter((commentGroup: any) => commentGroup.data.length > 0);
    
  }
  
  return (
    <div className={styles.rightSideBar}>
      <ul className={styles.tabBox}>
        <li
          className={selectedTab === "Open" ? styles.active : ""}
          onClick={() => setSelectedTab("Open")}
        >
          Open
        </li>
        <li
          className={selectedTab === "Resolved" ? styles.active : ""}
          onClick={() => setSelectedTab("Resolved")}
        >
          Resolved
        </li>
        <li
          className={selectedTab === "Approved" ? styles.active : ""}
          onClick={() => setSelectedTab("Approved")}
        >
          Approved
        </li>
        <li
          className={selectedTab === "Cancelled" ? styles.active : ""}
          onClick={() => setSelectedTab("Cancelled")}
        >
          Cancelled
        </li>
      </ul>
      {filteredComments && filteredComments.length>0 && <StatusTab
        filteredComments={filteredComments}
        setActiveLowerBox={setActiveLowerBox}
        activeLowerBox={activeLowerBox}
        slideId={slideId}
      />}
      {module === "Modular" && miscellaneousComments && miscellaneousComments.length > 0 && <Miscellaneous filteredComments={miscellaneousComments} setActiveLowerBox={setActiveLowerBox} activeLowerBox={activeLowerBox} slideId={slideId }/>}
    </div>
  );
}
