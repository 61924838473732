import React from "react";
import styles from "./Confirm.module.css";

type successProps = {
    setDocDelete:Function
    handleDelete:Function
};
export default function Success({ setDocDelete, handleDelete }: successProps) {
    return (
        <div className={styles.successWrapper}>
            <div className={styles.boundary}>
                <div className={styles.cross} onClick={() => {
                    setDocDelete(false)
                    }}>X</div>
                <div className={styles.upperBox}>
                    <figure>
                        <img
                            className="img-responsive"
                            src={`assets/images/requestRaise.svg`}
                            alt=""
                        />
                    </figure>
                    <div className={styles.message}>Are you sure </div>
                </div>
                <div className={styles.lowerBox}>
                    <button className={styles.cancel} onClick={() => setDocDelete(false)}>No</button>
                    <button className={styles.accept} onClick={()=>handleDelete()}>Yes</button>
                </div>
            </div>
        </div>
    );
}
