import Image from "next/image";
import styles from "./Slides.module.css";
import { useCanvasContext } from "@/libs/context/CanvasContext";
import { useEffect } from "react";
import imageLoader from "@/libs/helpers/imageLoader";
import { useGlobalContext } from "@/libs/context/GlobalContext";

type props = {
    handleCheckSlide: Function;
    checkedSlides: number[];
    setCurrentSlide: Function;
    slideDataApi: any;
    currentSlide: number;
    setCheckedSlides: Function;
    slideId: string;
    addNewSlide: Function;
    setCustomSlideDelete: Function;
    setDeleteSlideId?: any
    setDeleteSlideIndex?: any
};
export function Slides({
    handleCheckSlide,
    checkedSlides,
    setCurrentSlide,
    slideDataApi,
    currentSlide,
    setCheckedSlides,
    addNewSlide,
    setCustomSlideDelete,
    setDeleteSlideId,
    setDeleteSlideIndex
}: props) {
    const { setSlideContent, comments } = useCanvasContext();
    const { props: { syncing, enableEditing } } = useGlobalContext();
    useEffect(() => {
        slideDataApi[currentSlide - 1] &&
            setSlideContent!(slideDataApi[currentSlide - 1].slideData);
    }, [currentSlide]);

    const selectAllSlides = () => {
        // Check if all slides are already selected
        if (checkedSlides.length === slideDataApi.length) {
            // If all slides are already selected, clear the selection
            setCheckedSlides([]);
        } else {
            // If not all slides are selected, select all slides
            const allSlideIndexes = slideDataApi.map((_: any, idx: number) => idx);
            setCheckedSlides(allSlideIndexes);
        }
    };
    function getSlideStatus(comments: any) {
        const statuses = comments.map((comment: any) => comment.status);
        // If any status is 'Open', return 'Open'
        if (statuses.includes("Open")) {
            return "open";
        } else if (!statuses.includes("Open") && statuses.includes('Resolved')) {
            return 'resolved'
        }
        else {
            return "";
        }

    }

    return (
        <div className={styles.leftSideBar}>
            {/* <div className={styles.selectAll} onClick={() => selectAllSlides()}>Select All</div> */}
            <div className={styles.slidePages}>
                {slideDataApi &&
                    slideDataApi.map((_s: any, idx: number) => {
                        const slideId = _s.slideData.slideId;
                        const slideComments =
                            (comments &&
                                comments.find((comment: any) => comment.slideId === slideId)
                                    ?.data) ||
                            [];
                        const slideStatus = getSlideStatus(slideComments);
                        if (_s?.slideData?.type === "Custom") {
                            return (
                                <div
                                    className={`${styles.pageBox} ${currentSlide === idx + 1 ? styles.active : ""
                                        } ${syncing ? 'disabled' : ""}`}
                                    id={`slide_${idx + 1}`}
                                    key={idx}
                                    onClick={() => (
                                        !syncing &&  setSlideContent!(_s.slideData),
                                        !syncing && setCurrentSlide(idx + 1)
                                    )}
                                >
                                    <div className={`${styles.slide}`}>
                                        <Image
                                            width={129}
                                            height={87}
                                            className="img-responsive"
                                            src={_s.thumbNail}
                                            alt="bg"
                                            loader={imageLoader}
                                        />
                                    </div>
                                    <div className={styles.pageNo}>
                                        <span className="font14 text222">
                                            {idx < 9 ? `0${idx + 1}` : idx + 1}
                                        </span>
                                        {/* <div
                                    className={`${styles.pgnBerimgbox} ${checkedSlides.includes(idx) ? styles.active : ''
                                        }`}
                                    onClick={() => handleCheckSlide(idx)}
                                >
                                    <Image width={8} height={6} src="/assets/images/check.svg" alt="" />
                                </div> */}
                                        <div className={styles.slideName} title={_s.slideData.type}>
                                            {_s.slideData.type.length > 10
                                                ? `${_s.slideData.type.substring(0, 10)}...`
                                                : _s.slideData.type}
                                        </div>
                                    </div>
                                    {!enableEditing &&
                                        <div className={styles.detIocnbtbox} onClick={(e) => {
                                            e.stopPropagation()
                                            setCustomSlideDelete(true)
                                            setDeleteSlideIndex(idx)
                                            setDeleteSlideId(_s?.slideData?.slideId)
                                        }}>
                                            <img src="/assets/images/deletbinicon.svg" alt="" />
                                        </div>
                                    }
                                    {
                                        <div className={styles.slideStatus}>
                                            <div
                                                className={`${styles.Dot} ${styles[slideStatus]}`}
                                            ></div>
                                        </div>
                                    }
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    className={`${styles.pageBox} ${currentSlide === idx + 1 ? styles.active : ""
                                        } ${syncing ? 'disabled' : ""} ${_s.slideData.isVisible ? styles.notExcluded : styles.excluded}`}
                                    id={`slide_${idx + 1}`}
                                    key={idx}
                                    onClick={() => (
                                        !syncing && setSlideContent!(_s.slideData),
                                        !syncing && setCurrentSlide(idx + 1)
                                    )}
                                >
                                    <div className={`${styles.slide}`}>
                                        <Image
                                            width={129}
                                            height={87}
                                            className="img-responsive"
                                            src={_s.thumbNail}
                                            alt="bg"
                                            loader={imageLoader}
                                        />
                                    </div>
                                    <div className={styles.pageNo}>
                                        <span className="font14 text222">
                                            {idx < 9 ? `0${idx + 1}` : idx + 1}
                                        </span>
                                        {/* <div
                                    className={`${styles.pgnBerimgbox} ${checkedSlides.includes(idx) ? styles.active : ''
                                        }`}
                                    onClick={() => handleCheckSlide(idx)}
                                >
                                    <Image width={8} height={6} src="/assets/images/check.svg" alt="" />
                                </div> */}
                                        <div className={styles.slideName} title={_s.slideData.type}>
                                            {_s.slideData.type.length > 10
                                                ? `${_s.slideData.type.substring(0, 10)}...`
                                                : _s.slideData.type}
                                        </div>
                                    </div>
                                    {/* {currentSlide == idx + 1 && (
                                <div className={styles.detIocnbtbox}>
                                    <img src="/assets/images/deletbinicon.svg" alt="" />
                                </div>
                            )} */}
                                    {
                                        <div className={styles.slideStatus}>
                                            <div
                                                className={`${styles.Dot} ${styles[slideStatus]}`}
                                            ></div>
                                        </div>
                                    }
                                </div>
                            );
                        }
                    })}
            </div>
            {!enableEditing && <div className={styles.addSlide} onClick={() => addNewSlide()}>
                + Add Slide
            </div>}
        </div>
    );
}
